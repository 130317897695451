






















































































import {
  computed,
  defineComponent,
  ref,
  useStore,
} from '@nuxtjs/composition-api'
import Popup from '@/packages/basis/components/Popup.vue'
import BlockButtons from '@/components/BlockButtons.vue'
import SectionBasic from '@/components/SectionBasic.vue'
import { sectionCoreProps } from '@/mixins/sectionCore'
import { ButtonAction } from '@/contracts/ButtonHeroAction'

export default defineComponent({
  name: 'SectionOsagoInfo',
  components: { BlockButtons, SectionBasic, Popup },
  mixins: [sectionCoreProps],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore()
    const isMobileMq = computed(() => store.getters['mediaQuery/isMobileMq'])
    const open = ref<boolean>(false)
    const buttons: ButtonAction[] = [
      {
        id: '1',
        padding: true,
        label: 'Узнать',
        labelColor: '#D70E4B',
        background: '#FFFFFF',
        labelHoverColor: '#990032',
        backgroundHover: '#FFFFFF',
        name: 'button',
        arrow: false,
        type: 'button',
        color: '',
      },
    ]

    const popupButtons: ButtonAction[] = [
      {
        id: '1',
        padding: true,
        label: 'Понятно',
        labelColor: '#D70E4B',
        borderColor: '#D70E4B',
        background: '#FFFFFF',
        labelHoverColor: '#990032',
        backgroundHover: '#FFFFFF',
        name: 'button',
        arrow: false,
        type: 'button',
        color: '',
      },
    ]

    const openPopupHandle = () => {
      open.value = true
    }
    const closePopupHandle = () => {
      open.value = false
    }

    return {
      buttons,
      isMobileMq,
      open,
      popupButtons,
      openPopupHandle,
      closePopupHandle,
    }
  },
})
